.FeaturedProducts {
  > header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(28px, calc(36vw / 7.68), 48px);
    text-align: center;
    h2 {
      margin-bottom: clamp(12px, calc(20vw / 7.68), 20px);
    }
  }
  .list {
    display: grid;
    gap: clamp(28px, calc(28vw / 7.68), 48px) 32px;
    @media (min-width: 1050px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
