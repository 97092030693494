.Info {
  > div:first-child {
    margin-bottom: clamp(6px, calc(12vw/7.68), 12px);
  }
  h1 {
    margin-bottom: clamp(20px, calc(32vw / 7.68), 36px);
  }
  .categories {
    margin: clamp(20px, calc(32vw / 7.68), 36px) 0;
    width: fit-content;
    display: flex;
    gap: clamp(8px, calc(16vw / 7.68), 16px);
    flex-wrap: wrap;
    align-items: center;
    padding: 4px;
    border-radius: 26px;
    border: 1.5px solid var(--primary-300, #fff6f9);
    li {
      display: grid;
    }
    a {
      display: flex;
      align-items: center;
      gap: 6px;
      height: 44px;
      line-height: 44px;
      padding: 0 24px;
      border-radius: 22px;
      font-size: clamp(calc(12rem / 16), calc(14vw / 7.68), calc(14rem / 16));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid var(--primary-400, #ffd7e4);
      background: var(--primary-300, #fff6f9);
      transition: background-color 0.5s, border-color 0.5s;
      &:hover {
        background: var(--primary-400, #ffd7e4);
      }
      &[aria-current='page'] {
        border-color: var(--primary-500, #ffb8ce);
        background: var(--primary-400, #ffd7e4);
      }
    }
  }
  .price {
    color: var(--secondary-600, #504752);
    del {
      font-size: calc(14rem / 16);
      color: var(--secondary-500, #726378);
    }
  }
  .omnibus {
    font-size: calc(14rem / 16);
    margin: clamp(4px, calc(8vw / 7.68), 8px) 0 clamp(12px, calc(20vw / 7.68), 20px);
  }
  .paymentInfo {
    margin-top: clamp(12px, calc(20vw / 7.68), 20px);
    font-size: calc(14rem / 16);
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px 8px;
    }
    svg {
      height: 16px;
      max-width: 32px;
    }
  }
}
