.Newsletter {
  display: grid;
  justify-content: space-between;
  gap: clamp(32px, calc(48vw / 7.68), 64px);
  @media (min-width: 900px) {
    grid-template-columns: 1.2fr 1fr;
    .Form {
      margin-left: auto;
    }
  }
  header {
    max-width: calc(587rem / 16);
    h2 {
      max-width: calc(532rem / 16);
      margin-bottom: clamp(12px, calc(20vw / 7.68), 20px);
    }
  }
}

.Form {
  position: relative;
  width: 100%;
  max-width: calc(483rem / 16);
  display: grid;
  gap: clamp(12px, calc(16vw / 7.68), 16px);
  &:has([data-element='FormState']) {
    & > *:not([data-element='FormState']) {
      display: none;
    }
  }
}
