.ColumnHeaderAndStats {
  display: grid;
  gap: clamp(72px, calc(106vw / 7.68), 106px);
  align-items: center;
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1.2fr;
  }
  header {
    max-width: calc(587rem / 16);
    .paragraph {
      margin-top: clamp(12px, calc(16vw / 7.68), 20px);
      & > *:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .cta {
      margin-top: clamp(24px, calc(32vw / 7.68), 32px);
    }
  }
  .img {
    max-width: 470px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    .circle {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      aspect-ratio: 1/1;
      overflow: hidden;
      border-radius: 50%;
      border: 3px solid var(--primary-300, #fff6f9);
      img {
        width: 56%;
      }
    }
    .Crown {
      position: absolute;
      width: clamp(47px, calc(78vw / 7.68), 78px);
      left: 60%;
      top: -15px;
      animation: Crown 1s var(--easing) both;
      transform-origin: left bottom;
      @keyframes Crown {
        0% {
          transform: scale(0) rotate(-45deg);
        }
        100% {
          transform: scale(1);
        }
      }
    }
    .Stars {
      position: absolute;
      width: clamp(31px, calc(51vw / 7.68), 51px);
      left: 0;
      bottom: 0;
      g {
        animation: Stars 1s var(--easing) both;
        transform-box: fill-box;
        transform-origin: center;
        &:nth-child(1) {
          animation-delay: 1s;
        }
        &:nth-child(2) {
          animation-delay: 1.1s;
        }
        &:nth-child(3) {
          animation-delay: 1.2s;
        }
      }
      @keyframes Stars {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
    }
    .list {
      .item {
        position: absolute;
        min-width: 120px;
        &:nth-child(1) {
          top: 22px;
          left: -22px;
        }
        &:nth-child(1) {
          top: 22px;
          left: -22px;
        }
        &:nth-child(2) {
          top: 24%;
          right: -24px;
        }
        &:nth-child(3) {
          top: 90%;
          left: 45%;
        }
        padding: clamp(4px, calc(12vw / 7.68), 12px);
        padding-right: clamp(8px, calc(24vw / 7.68), 24px);
        border-radius: 4px;
        border: 1px solid var(--primary-400, #ffd7e4);
        background: var(--primary-300, #fff6f9);
        .icon {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%, -50%);
          width: clamp(24px, calc(40vw / 7.68), 40px);
          height: clamp(24px, calc(40vw / 7.68), 40px);
          border-radius: 50%;
          background: var(--primary-400, #ffd7e4);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: clamp(12px, calc(24vw / 7.68), 24px);
            height: clamp(12px, calc(24vw / 7.68), 24px);
          }
        }
        span {
          font-family: var(--font-libre-baskerville);
          font-size: clamp(18px, calc(24vw / 7.68), 24px);
        }
        p {
          font-size: clamp(12px, calc(14vw / 7.68), 14px);
          line-height: 146%;
        }
      }
    }
  }
}
