.FormState {
  &[data-is-success='true'] {
    h3 {
      color: var(--success-500, #298543);
    }
  }
  &[data-is-success='false'] {
    h3 {
      color: var(--error-500, #cb2f2f);
    }
  }
  h3 {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    font-size: calc(18rem / 16);
  }
  p {
    margin: 16px 0 28px;
  }
  animation: FormState 0.5s both;
  @keyframes FormState {
    from {
      opacity: 0;
      transform: translateY(13px);
    }
    to {
      opacity: 1;
    }
  }
}
