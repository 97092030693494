.Listing {
  > header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(36px, calc(48vw / 7.68), 64px);
    text-align: center;
    h1 {
      margin-bottom: clamp(12px, calc(16vw / 7.68), 20px);
    }
  }
  .categories {
    margin: 0 auto clamp(52px, calc(64vw / 7.68), 84px);
    width: fit-content;
    ul {
      display: flex;
      gap: clamp(8px, calc(16vw / 7.68), 16px);
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 4px;
      border-radius: 32px;
      border: 1.5px solid var(--primary-300, #fff6f9);
      li {
        display: grid;
      }
    }
    a {
      display: grid;
      grid-template-columns: auto 1fr;
      img {
        width: clamp(36px, calc(48vw / 7.68), 48px);
        height: clamp(36px, calc(48vw / 7.68), 48px);
        object-fit: cover;
        border-radius: 50%;
      }
      align-items: center;
      gap: 6px;
      padding: 4px 16px 4px 4px;
      border-radius: 28px;
      font-size: clamp(calc(12rem / 16), calc(14vw / 7.68), calc(14rem / 16));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid var(--primary-400, #ffd7e4);
      background: var(--primary-300, #fff6f9);
      transition: background-color 0.5s, border-color 0.5s;
      &:hover {
        background: var(--primary-400, #ffd7e4);
      }
      &[aria-current='page'] {
        border-color: var(--primary-500, #ffb8ce);
        background: var(--primary-400, #ffd7e4);
      }
    }
    position: relative;
    .Brushes {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      pointer-events: none;
      path {
        animation: Brushes 0.8s var(--easing) both;
        transform-origin: bottom left;
        transform-box: fill-box;
        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.1s;
        }
        &:nth-child(3) {
          animation-delay: 0.2s;
        }
        &:nth-child(4) {
          animation-delay: 0.3s;
        }
        &:nth-child(5) {
          animation-delay: 0.4s;
        }
        &:nth-child(6) {
          animation-delay: 0.5s;
        }
        &:nth-child(7) {
          animation-delay: 0.6s;
        }
        &:nth-child(8) {
          animation-delay: 0.7s;
        }
        &:nth-child(9) {
          animation-delay: 0.8s;
        }
        @keyframes Brushes {
          from {
            transform: scale(0);
          }
          to {
            transform: scale(1);
          }
        }
      }
    }
  }
}

.Products {
  display: grid;
  gap: clamp(28px, calc(28vw / 7.68), 48px) 32px;
  @media (min-width: 1050px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
