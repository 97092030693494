.BlogPostCard {
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  .link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: inherit;
  }
  border-radius: 20px;
  overflow: hidden;
  .img {
    overflow: hidden;
    z-index: -1;
    img {
      aspect-ratio: 381 / 259;
      object-fit: cover;
      height: calc(100% + 5px);
      transition: transform 0.5s var(--easing);
    }
  }
  &:has(a:hover) {
    img {
      transform: translateY(-5px);
    }
  }
  .wrapper {
    margin: -28px 0 0 0;
    border-radius: 20px;
    border: 1px solid var(--primary-500, #ffb8ce);
    background: var(--primary-200, #fffdfd);
    padding: clamp(12px, calc(28vw / 7.68), 28px);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .subtitle {
      margin: clamp(8px, calc(12vw / 7.68), 12px) 0 clamp(20px, calc(24vw / 7.68), 24px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
    }
    a {
      margin-top: auto;
      position: relative;
      z-index: 3;
    }
  }
  @media (max-width: 1049px) and (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
    .img {
      border-radius: 20px 0px 0px 20px;
      img {
        height: 100%;
        width: calc(100% + 5px);
        max-width: calc(100% + 5px);
      }
    }
    &:has(a:hover) {
      img {
        transform: translateX(-5px);
      }
    }
    .wrapper {
      margin: 0 0 0 -28px;
      align-items: flex-start;
      text-align: left;
    }
  }
}
