.Checkbox {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  width: fit-content;
  .icon {
    margin: 8px;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: grid;
    place-items: center;
    border: 1px solid var(--primary-600);
    transition: border-color 0.5s, background-color 0.5s;
    position: relative;
    input {
      position: absolute;
      opacity: 0;
    }
    svg {
      stroke-dasharray: 11;
      stroke-dashoffset: 11;
      transition: stroke-dashoffset 0.5s var(--easing);
    }
  }
  p {
    margin-top: 8px;
    > span[role='alert'] {
      margin-left: auto;
      margin-top: 4px;
    }
  }

  &:hover {
    .icon {
      border-color: var(--primary-700);
      background-color: var(--primary-300);
    }
  }
  &:has(input:checked) {
    .icon {
      svg {
        stroke-dashoffset: 0;
      }
    }
  }
  &:has(input:focus-visible) {
    .icon {
      outline: 2px solid var(--primary-500);
      outline-offset: 3px;
    }
  }
  &[aria-invalid='true'] {
    .icon {
      border-color: var(--error-500);
      background-color: var(--error-200);
    }
  }
}
