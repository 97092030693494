.FlexTiles {
  header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(24px, calc(36vw / 7.68), 48px);
    text-align: center;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    $gap: clamp(16px, calc(24vw / 7.68), 32px);
    gap: $gap;
    .item {
      text-align: center;
      padding: clamp(12px, calc(16vw / 7.68), 24px) clamp(12px, calc(16vw / 7.68), 28px);
      border-radius: 20px;
      border: 1px solid var(--primary-500, #ffb8ce);
      width: calc(100% / 3 - $gap * (1 - 1 / 3));
      h2 {
        margin-bottom: clamp(20px, calc(24vw / 7.68), 24px);
      }
      @media (max-width: 1099px) {
        width: calc(100% / 2 - $gap * (1 - 1 / 2));
      }
      @media (max-width: 699px) {
        width: 100%;
      }
    }
  }
}
